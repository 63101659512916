import { CancelToken } from 'axios';

import { FilterOptions } from '@/constants/SystemConstants';

import BaseRequestService from './RequestService';

class SessionRequestService extends BaseRequestService {
  async getExperimentSessions(
    labId: any,
    filter: any,
    instancePageSize: number,
    instancePageNumber: number,
    cancelToken?: CancelToken,
  ) {
    const skip = instancePageSize * instancePageNumber;

    const { searchValue, lastRunTimeRange, startDate, endDate } = filter;

    let dataCall = `sessions?$filter=(labId eq ${Number(labId)})`;
    let totalCall = `sessions/metadata?$filter=(labId eq ${Number(labId)})`;

    // Currently only filtering by labId and name
    if (searchValue) {
      dataCall += ` and contains(name, '${searchValue}')`;
      totalCall += ` and contains(name, '${searchValue}')`;
    }

    if (lastRunTimeRange && lastRunTimeRange !== FilterOptions.ALL) {
      if (startDate) {
        dataCall += ` and created gt '${startDate}'`;
        totalCall += ` and created gt '${startDate}'`;
      }

      if (endDate) {
        dataCall += ` and created lt '${endDate}'`;
        totalCall += ` and created lt '${endDate}'`;
      }
    }

    if (skip > 0) {
      dataCall += `&$skip=${skip}`;
    }

    dataCall += `&$top=${instancePageSize}&$orderBy=created desc`;
    totalCall += '&$count=true';

    try {
      const { data } = await this.get(dataCall, null, { cancelToken });
      const count = await this.get(totalCall, null, { cancelToken });

      return { experimentSessions: data, count: count.data[0]?.Count || 0 };
    } catch (error) {
      throw new Error('[SessionRequestService.getExperimentSessions] An error has occurred:', error);
    }
  }

  async getExperimentSession(sessionId: string, cancelToken?: CancelToken) {
    if (!sessionId) {
      console.error('[getExperimentSession] Error! SessionId is empty.');

      return null;
    }

    const url = `sessions/${sessionId}`;

    try {
      const { data } = await this.get(url, null, { cancelToken });

      return { data };
    } catch (error) {
      throw new Error('[SessionRequestService.getExperimentSession] An error has occurred:', error);
    }
  }

  async getExperimentSessionsStatus(
    labId: any,
    filter: any,
    instancePageSize: number,
    instancePageNumber: number,
    cancelToken?: CancelToken,
  ) {
    const skip = instancePageSize * instancePageNumber;

    const { searchValue, lastRunTimeRange, startDate, endDate } = filter;

    let dataCall = `sessions/status?$filter=(labId eq ${Number(labId)})`;
    let totalCall = `sessions/metadata?$filter=(labId eq ${Number(labId)})`;

    // Currently only filtering by labId and name
    if (searchValue) {
      dataCall += ` and contains(name, '${searchValue}')`;
      totalCall += ` and contains(name, '${searchValue}')`;
    }

    if (lastRunTimeRange && lastRunTimeRange !== FilterOptions.ALL) {
      if (startDate) {
        dataCall += ` and created gt '${startDate}'`;
        totalCall += ` and created gt '${startDate}'`;
      }

      if (endDate) {
        dataCall += ` and created lt '${endDate}'`;
        totalCall += ` and created lt '${endDate}'`;
      }
    }

    if (skip > 0) {
      dataCall += `&$skip=${skip}`;
    }

    dataCall += `&$top=${instancePageSize}&$orderBy=created desc`;
    totalCall += '&$count=true';

    try {
      const { data } = await this.get(dataCall, null, { cancelToken });
      const count = await this.get(totalCall, null, { cancelToken });

      return { experimentSessions: data, count: count.data[0]?.Count || 0 };
    } catch (error) {
      throw new Error('[SessionRequestService.getExperimentSessionsStatus] An error has occurred:', error);
    }
  }

  async getSessionSteps(sessionId: string, cancelToken?: CancelToken) {
    if (!sessionId) {
      console.error('[getSessionSteps] Error! SessionId is empty.');

      return null;
    }

    const url = `sessions/${sessionId}/steps`;

    try {
      const { data } = await this.get(url, { cancelToken });

      return data;
    } catch (error) {
      throw new Error('[SessionRequestService.getSessionSteps] An error has occurred:', error);
    }
  }

  async getIPAddress(labId: number, cancelToken?: CancelToken) {
    const url = `lab-systems/${labId}/ip-addresses`;

    try {
      const { data } = await this.get(url, { cancelToken });

      return data;
    } catch (error) {
      throw new Error('[SessionRequestService.getIPAddress] An error has occurred:', error);
    }
  }
}

export const GanymedeSessionRequestService = new SessionRequestService();
